<script setup lang="ts">
import { useAppStore } from '~/stores/app'
defineProps({
  to: {
    type: String,
    required: true,
  },
})

const app = useAppStore()
const isDifferentAppVersion = computed(() => app.isDifferentAppVersion)
</script>
<template>
  <a v-if="isDifferentAppVersion" :href="to" :class="$attrs.class">
    <slot />
  </a>
  <nuxt-link v-else :to="to" :class="$attrs.class">
    <slot />
  </nuxt-link>
</template>
